"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SentenceStructure = exports.FontFactor = exports.DefaultOptions = exports.LanguageMap = exports.Settings = void 0;
const Settings = {
    defaultLang: 'en',
    title: 'BrushBattle',
    titleSize: 38,
    font: 'JustMeAgainDownHere',
    background: 0xffffff,
    maxWidth: 800,
    navHeight: 60,
    battleColor: 0x4bff7e,
    grayColor: 0x808080,
    lightLightGrayColor: 0xf5f5f5,
    lightGrayColor: 0xe4e4e4,
    darkGrayColor: 0x606060,
    disabledColor: 0xe6e6e6,
    disabledColor2: 0xb3b3b3,
    blackColor: 0x000000,
    drawColor: 0x000000,
    speedColor: 0x22fff2,
    practiceColor: 0xffc7f9,
    levelsColor: 0xffc7f9,
    availableColor: 0x85981b,
    unavailableColor: 0x9f3131,
    successColor: 0x217e00,
    failColor: 0xffffff,
    errorColor: 0xff0000,
    statsColor: 0xffff8e,
    adsColor: 0xf1fcc5,
    rewardColor: 0xffffe0,
    atelierColor: 0xd9c28f,
    othersColor: [0xbab6f7, 0xbaf9e8, 0xc2f9bb, 0xf1f8b2, 0xf8dfaf, 0xf0b5ba],
    guessColor: 0xffc677,
    statsAccent: 0xfcfcb3,
    whiteColor: 0xffffff,
    canvasColor: 0xffffff,
    positiveColor: 0x4bff7e,
    negativeColor: 0xffc5c5,
    splashColor: 0xffff8e,
    vibrateLength: 150,
    loudMusicVolume: 0.18,
    defaultMusicVolume: 0.09,
    privacy: "https://brightspike.io/privacy",
    about: "https://brightspike.io/about-credits",
    googleplay: "https://play.google.com/store/apps/details?id=io.brightspike.brushbattle",
    appstore: "https://apps.apple.com/app/brushbattle/id6504861396"
};
exports.Settings = Settings;
var SentenceStructure;
(function (SentenceStructure) {
    SentenceStructure[SentenceStructure["VAO"] = 0] = "VAO";
    SentenceStructure[SentenceStructure["OAV"] = 1] = "OAV";
    SentenceStructure[SentenceStructure["AOV"] = 2] = "AOV";
})(SentenceStructure || (exports.SentenceStructure = SentenceStructure = {}));
const FontFactor = {
    ja: {
        size: 0.8,
        font: 'Hiragino Kaku Gothic Pro'
    },
    ar: {
        size: 0.8,
        font: 'Tahoma'
    },
    zh: {
        size: 0.8,
        font: 'Heiti'
    },
    hi: {
        size: 0.7,
        font: 'Nirmala UI'
    },
    vn: {
        size: 0.65,
        font: 'Helvetica'
    }
};
exports.FontFactor = FontFactor;
const LanguageMap = {
    en: 'English',
    es: 'Español',
    fr: 'Français',
    de: 'Deutsch',
    it: 'Italiano',
    pt: 'Português',
    ja: '日本語',
    ar: 'عربي',
    zh: '中国人',
    hi: 'हिंदी',
    vn: 'Tiếng Việt',
    tr: 'Türkçe',
    pl: 'Polski',
    nl: 'Nederlands'
};
exports.LanguageMap = LanguageMap;
const DefaultOptions = {
    music: true,
    sound: true,
    vibrate: true
};
exports.DefaultOptions = DefaultOptions;
